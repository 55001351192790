@use "sass:color";

@import 'fonts/font-awesome';
@import 'fonts/nunito';
@import 'react-datetime';
@import '_animation';

#wrapper {
  overflow-x: hidden;
}

a.link:hover {
  color: #d1eaff;
}

a.link {
  color: #8bcaff;
}

.sidebar_button > .sidebar_button:active,
.sidebar_button:active > .sidebar_button {
  color: #d0c6ff;
  font-weight: 600;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #1cc88a !important;
  border-color: #1cc88a !important;
}

.sidebar-component {
  background-image: none !important;
}

.image {
  background-color: white !important;
}

.badge-success {
  color: #fff;
  background-color: #1b7b58 !important;
}

#sidebarMenu {
  .nav-link {
    color: #fff;

    &:hover {
      transition: all 0.5s ease;
      filter: brightness(300%);
    }
  }
}

.nav-tabs {
  .nav-link {
    background-color: #f7f7f7 !important;
    color: #5a5c69 !important;
    border: 0;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
    }

    &:hover {
      filter: brightness(90%);
    }

    &.active {
      filter: brightness(80%);
    }
  }

  .tab-background {
    background-color: #f7f7f7 !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eaecf4 !important;
  opacity: 1 !important;
  border: 1px solid #d1d3e2 !important;
}

.input-group-prepend {
  .form-control {
    border-right: 0 !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.input-group-text,
.form-control {
  border-radius: 3px;
}

.btn {
  transition: all 0.5s ease;

  &:hover {
    filter: brightness(85%);
  }

  &:disabled:hover {
    filter: none;
  }

  &-success.order-name-btn,
  &-danger.order-name-btn {
    width: 42px;
  }
}

.hover-fade:hover {
  transition: all 0.5s ease;
  filter: brightness(85%);
}

.carousel-indicators .active {
  background-color: #000000;
}

.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}

.carousel-control-prev {
  justify-content: left;
}

.carousel-control-next {
  justify-content: right;
}


.z-1 {
  z-index: 1;
}

.highlight {
  outline: solid 1px red;
}

.fb-ad-preview-box {
  background: #eee;
  padding: 15px;
}

.fb-metrics-box {
  background: #eee;
  padding: 15px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.react-bootstrap-table td {
  vertical-align: middle;
}

//overly specific fix for google chart in invetory management.
//upcoming redesign of utilization chart will allow deletion of this rule.
.inventory-management-google-chart {
  rect {
    fill: transparent;
  }

  > div > div > div > svg > g > text {
    display: none;
  }

  > div > div > div > svg + div:first-of-type {
    overflow: hidden !important;
  }
}

#inventory-home-table {
  margin-bottom: 0;

  th {
    font-size: 13.33px;
    font-weight: 400;
  }

  td {
    vertical-align: middle;
    font-size: 16px;
    font-weight: 400;
  }

  tbody tr {
    height: 108px !important; //wip
  }
}
//MUI pagination overrides
.MuiTablePagination-selectLabel ,.MuiTablePagination-displayedRows{
  margin-bottom: 0;
}