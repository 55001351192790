@tailwind base;
@tailwind components;
@tailwind utilities;
select{
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path fill="%23CCCCCC" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>') no-repeat;
  background-size: 20px;
  background-position: calc(100% - 7px) 50%;
  background-repeat: no-repeat;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

$theme-colors: (
  "primary": #7155FF,
  "danger": #EF767A,
  "success": #4FAD80,
);

@import '~bootstrap/scss/bootstrap';
@import 'styles/app';

body {
  position: relative;
  margin: 0;
  font-family: 'Roboto', 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5a5c69 !important;
  overflow: hidden; // Prevent scrollbars appearing-> overflowY is visible for the main container
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* React Toastify */

.Toastify__toast--success {
  background: #87F5C0 !important;
}

.Toastify__toast--error {
  background: #EF767A !important;
}

.Toastify__toast-body {
  font-family: "Roboto, Helvetica, sans-serif";
  color: #3A3B4F;
}

.Toastify__progress-bar {
  color: #3A3B4F;
}

.Toastify__close-button {
  color: #3A3B4F;
}

// .Toastify__progress-bar {
//   background: #333F48 !important;
// }

.audience-popover {
  & > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
    border-top-color: #101010 !important;
  }
}
button[disabled]{
  cursor: not-allowed;
}
.network_branding_toast{
 font-family: 'Roboto', 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;;

}